
import React from 'react'
import Nav from './components/Nav'
import {  createTheme, ThemeProvider } from '@mui/material'
import { theme } from './utils/theme'
import Footer from './components/Footer';
import "./App.css";
function App() {
const themes = createTheme(theme);

  return (
   
    <ThemeProvider theme={themes}>
      <Nav/>
      <Footer/>
      </ThemeProvider>
  )
}

export default App