import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Stack from "@mui/material/Stack";
import { Box, Button } from "@mui/material";

export default function Search() {
  const [value, setValue] = React.useState(new Date());

  return (
    <Box sx={{ margin: 5, color: '#E3C57F' }} >
      <LocalizationProvider dateAdapter={AdapterDateFns} >
        <Stack spacing={2} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Box sx={{ flexDirection: "col", marginRight: 3 }}>
              <DatePicker
                label='Check In'
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box sx={{ flexDirection: "col", marginRight: 3, }}>
              <DatePicker
                label='Check Out'
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box sx={{ flexDirection: "col", marginRight: 3 }}>
              <TextField type='number' label='Adults'></TextField>
            </Box>
            <Box sx={{ flexDirection: "col", marginRight: 3 }}>
              <TextField type='number' label='Children'></TextField>
            </Box>
            <Box sx={{ flexDirection: "col", marginRight: 3 }}>
              <Button variant='outlined'> Search</Button>
            </Box>
          </Box>
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}
