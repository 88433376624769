
import React from 'react'
import Div1 from './Div1'
import Div2 from './Div2'
import FeaturedRooms from './FeaturedRooms'
import Ratings from './Ratings'
import Search from './Search'

function Index() {
  return (
      <>
  <Div1/>
  <Search />
  <Div2/>
  <FeaturedRooms/>
  <Ratings/>
  </>
  )
}

export default Index