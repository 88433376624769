import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";
import Index from "../HomeComp/Index";

import { logo } from "../images/image";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Nav = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box
        component='div'
        sx={{
          backgroundColor: " #591934",
          padding: 1,
          backgroundImage: `url(${logo})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}
      >
        <Box sx={{ width: "100%", paddingTop: 27, }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            textColor='secondary'
            indicatorColor='secondary'
          >
            <Tab label='Home' sx={{ color: "#fff" }} {...a11yProps(0)} />
            <Tab label='Rooms & Cottage' sx={{ color: "#fff" }} />
            <Tab label='Events' sx={{ color: "#fff" }} />
            <Tab label='Promos' sx={{ color: "#fff" }} />
            <Tab label='Shared Gallery' sx={{ color: "#fff" }} />
            <Tab label='About Us' sx={{ color: "#fff" }} />
            <Tab label='Contact' sx={{ color: "#fff" }} />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Index />
      </TabPanel>
    </Box>
  );
};
export default Nav;
