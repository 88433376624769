import * as React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { GrInstagram, GrFacebook } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";


export default function Footer() {
  return (
    <Box
      component='div'
      sx={{
        backgroundColor: "#591934",
        margin: "auto",
        textAlign: "center",
        padding: 1,
        fontSize:{
          xs: '1%',
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          WebkitJustifyContent: "center",
          flexDirection: "row",
          margin: "auto",
          fontSize:{
            xs: '1%',
  
          }
        }}
      >
        <Box
          sx={{
            margin: "auto",
            flexDirection: "col",
            color: "gray",
            marginLeft: "17%",
          }}
        >
          <Typography fontSize={13} >Shared Gallery </Typography>
          <Typography fontSize={13} >About Us </Typography>
          <Typography fontSize={13} >Contact </Typography>
          <Button
            variant='outlined'
            sx={{ border: 1, borderRadius: "5vmax", color: "#DFDFDE", fontSize: 13 }}
          >
            Book Now
          </Button>
        </Box>
        <Divider
          orientation='vertical'
          flexItem
          sx={{ border: 2, color: "#F0A500" }}
        />

        <Box sx={{ margin: "auto", flexDirection: "col", color: "gray" }}>
          <Typography fontSize={13} sx={{marginBottom: 2}}>FOLLOW US</Typography>
          <GrFacebook
            style={{ color: "#4D77FF", fontSize: 30, marginRight: 30 }}
          />
          <GrInstagram
            style={{ color: "violet", fontSize: 30, marginRight: 30 }}
          />
          <BsTwitter style={{ color: "#4D96FF", fontSize: 30,marginRight: 30 }} />
        </Box>
        <Divider
          orientation='vertical'
          flexItem
          sx={{ border: 2, color: "#F0A500" }}
        />
        <Box sx={{ margin: "auto", flexDirection: "col", color: "gray",}}>
          <Typography fontSize={13} >How to Find us</Typography>
          <Typography fontSize={13} >Lost & Found</Typography>
          <Typography fontSize={13} >Legal Information</Typography>
          <Typography fontSize={13} >Personal Data Privacy & Security </Typography>
          <Typography fontSize={13} >Notice</Typography>
        </Box>
      </Box>

      <Typography fontSize={13} color='white'>
        &copy; 2022 by Mikaella's Resort and Events Place. All rights reserved.
      </Typography>
    </Box>
  );
}
