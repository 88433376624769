
export const theme = {
  palette: {
    type: 'dark',
    primary: {
      main: '#591934',
    },
    secondary: {
      main: '#E3C57F',
    },
    info: {
      main: '#2196f3',
    },
    text: {
      primary: '#AD8B73',
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};