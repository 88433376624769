import { Box, Typography } from "@mui/material";
import React from "react";

function Div2() {
  return (
    <Box
      component='div'
      sx={{
        backgroundColor: "#591934",
        textAlign: 'center',
        margin: 'auto',
        padding: 5
      }}
    >
      <Typography variant='h4' color='secondary'>
        RELAX, UNWIND & CELEBRATE <br/> <br/>
      </Typography>
      <Typography variant='h6' color='white' >
        Loremepsium Loremepsium Loremepsium LoremepsiumLoremepsium Loremepsium v
        Loremepsium Loremepsium v v Loremepsium v Loremepsium Loremepsium v v v
        Loremepsium Loremepsium Loremepsium v LoremepsiumLoremepsium vv
        LoremepsiumLoremepsiumv Loremepsium v LoremepsiumLoremepsium v
        Loremepsium Loremepsium v v vLoremepsium v
      </Typography>
    </Box>
  );
}

export default Div2;
