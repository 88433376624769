import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";

function Ratings() {
  return (
    <>
      <Box
        component='div'
        sx={{
          backgroundColor: "#591934",
          textAlign: "center",
          margin: "auto",
          padding: 5,
        }}
      >
        <Typography variant='h5' color='white'>
          RATING <br /> <br />
        </Typography>
        <Rating
          name='size-large'
          defaultValue={2}
          sx={{ fontSize:{
            xs: '3rem'
          }, justifyContent: "center" }}
        />
      </Box>

      <Box
        component='div'
        sx={{
          textAlign: "center",
          margin: "auto",
          padding: 5,
        }}
      >
        <Typography variant='h5' color='white'>
          TESTIMONIALS <br /> <br />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {" "}
          <Card
            sx={{
              maxWidth:{
                l: 30,
                xs: 15,

              },
              flexDirection: "col",
              padding: {
                l: 15,
                xs: 10
              },
              border: 1,
              bgcolor: "wheat",
              maxHeight: 300,
              marginRight: 10
            }}
          >
             <CardActionArea>
            <CardContent>
            <Rating name="size-large" defaultValue={2} sx={{ fontSize: 20}} />
            </CardContent>
          </CardActionArea>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default Ratings;
