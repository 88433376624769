import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { BG } from "../images/image";

function Div1() {
  return (
    <Box
      component='div'
      sx={{
        backgroundImage: `url(${BG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        position: 'center',
        paddingTop: 60,
      }}
    >
      <Box sx={{ bgcolor: '#F9D371', opacity: '60%', padding: 2}}>
      <Typography
        sx={{
          marginLeft: 5,
          fontWeight: "bold",
          display: "flex",
          flexDirection: "col",
          
        }}
      >
        <Typography variant='h4' sx={{ flexDirection: "row" }}>
          LOOKING FOR A VENUE?
          <br />
        </Typography>
        <Button
          sx={{
            border: 1,
            borderRadius: "8vmax",
            flexDirection: "row",
            marginLeft: "auto",
            width: "20%",
            padding: 2,
            marginRight: 5,
            bgcolor: '#fff'
          }}
        >
          INQUIRE NOW
        </Button>
      </Typography>
      <Typography variant='h6' sx={{ margin: 5, fontWeight: "bold" }}>
        Try Mikaella's Resort and Events Place for as low as PHP 12, 345
      </Typography>
      </Box>
    </Box>

  );
}

export default Div1;
